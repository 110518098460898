export default function Shipdrop(p5, xx, yy) {
  let x = xx;
  let y = yy;
  const r = 8;
  this.toDelete = false;

  this.show = () => {
    p5.noStroke();
    p5.fill(150, 0, 250);
    p5.ellipse(x, y, r*2, r*2);

  }

  this.hits = (flower) => {
    let d = p5.dist(x, y, flower.x, flower.y);
    if (d < r + flower.r) {
      return true;
    } else {
      return false;
    }
  }

  this.evaporate = () => {
    this.toDelete = true;
  }

  this.move = () => {
    y = y-5;
  }

}