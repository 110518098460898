import React, { useEffect } from "react";
import * as p5 from "p5";
 
const CustomShapes = () => {
  const SketchCustomShapes = p5 => {
    let angle = 0;
    let jung;

    p5.preload = () => {
      jung = p5.loadImage('/images/사진.jpg')
    }

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL)
    };
  
    p5.draw = () => {

      p5.background(0);

      p5.ambientLight(255);
      p5.ambientMaterial(100);
      //p5.directionalLight(255, 255, 255, 0, 0, -1);

      p5.rotateX(angle);
      p5.rotateY(angle*0.03);
      p5.rotateZ(angle*1.2);

      p5.texture(jung);
      p5.translate(-50, -50);
      //p5.box(50);
      //p5.fill(255);
      p5.beginShape();
      p5.vertex(0, 0, 0, 0, 0);
      p5.vertex(200, 0, 0, 625, 0);
      p5.vertex(200, 200, 0, 625, 625);
      p5.vertex(0, 100, 0, 0, 625);

      p5.endShape(p5.CLOSE);

      angle += 0.03;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchCustomShapes);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default CustomShapes;