import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Noto Sans KR"
  }
})

ReactDOM.render(
  <div>
    <MuiThemeProvider theme={theme}><App /></MuiThemeProvider>
  </div>,
  document.getElementById('root')
);

