import React, { useEffect } from "react";
import * as p5 from "p5";
 
const LorenzAttractor = () => {
  const SketchLorenzAttractor = p5 => {
    let x = 0.01;
    let y = 0;
    let z = 0;
    const a = 10;
    const b = 28;
    const c = 8.0/3.0;
    const points = [];


    p5.setup = () => {
      p5.createCanvas(800, 600, p5.WEBGL);
      p5.colorMode(p5.HSB);

    };
  
    p5.draw = () => {
      let dt = 0.01;
      let dx = (a * (y - x))*dt;
      let dy = (x * (b - z) -y)*dt;
      let dz = (x * y - c * z)*dt;

      x = x + dx;
      y = y + dy;
      z = z + dz;
      
      let camX = p5.map(p5.mouseX, 0, p5.width, 200, -200);
      let camY = p5.map(p5.mouseY, 0, p5.height, 200, -200);
      let camZ = p5.map(p5.mouseY, 0, p5.height, 200, -200);
      p5.camera(camX, camY, camZ, 0, 0, 0, 0, 1, 0);

      p5.background(0);

      let v = p5.createVector(x, y);
      points.push(v);
    //  p5.translate(p5.width/2, p5.height/2)
      p5.translate(0, 0, -80);
      p5.scale(5);
      p5.stroke(255);
      p5.noFill();

      //p5.point(v);
      let hu = 0;
      p5.beginShape();
      for ( let i = 0; i < points.length; i++) {
        // p5.push();
        // p5.translate(points[i].x, points[i].y, points[i].z);
        // p5.stroke(255);
        // p5.strokeWeight(0.1);
        // //p5.fill(255, 255, 0);
        // p5.sphere(0.1);
        // p5.pop();
        p5.stroke(hu, 255, 255);
        p5.vertex(points[i].x, points[i].y, points[i].z);
        // let offset = p5.createVector(p5.random(),p5.random(),p5.random());
        // offset.mult(0.1);
        // points.push(offset);
        hu += 0.1;
        if (hu > 255) hu = 0; 
      }
      p5.endShape();
    };
        
    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  
  };

  useEffect(() => {
    const p55 = new p5(SketchLorenzAttractor);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default LorenzAttractor;