import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

export default function Learning2D() {

  let id = [
        "P5draw",
        "P5color",
        "P5map",
        "P5random",
        "P5bouncingball",
        "P5bubbles",
        "P5bubblesintersection",
        "P5domevent",
        "P5cssparent",
        "P5filedragdrop",
        "P5sliderdance",
        "P5json",
        "P5trails",
        "P5nooverlap",
        "P5mst",
        "P5pixelarray",
        "P5perlinnoise",
       ];
       
  let idcard = [];

  id.map((idc, index) => (
    idcard[index] = (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" component="h2">{index+1}. {idc}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Link component={RouterLink} to={"l2dview/" + idc}>
              <Button variant="contained" color="primary">
                보기
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>)
  ))

  return (
     <div>
      {idcard.map((idc, index) => (
        <div key={index}>
          {idc}
        </div>
      ))}
    </div>
  );
}
