import React from "react";
import Sketch from "react-p5";

export default function P5bouncingball(props) {

  var ball = {
    x: 300,
    y: 200,
    xspeed: 4,
    yspeed: -3
  }
  
  var width = 450;
  var height = 400;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(width, height).parent(canvasParentRef);
  };

  const draw = (p5) => {
    p5.background(0);
    move();
    bounce();
    display(p5);
  }

  function move() {
    ball.x = ball.x + ball.xspeed;
    ball.y = ball.y + ball.yspeed;
  }

  function bounce() {
    if (ball.x > width || ball.x < 0) {
      ball.xspeed = ball.xspeed * -1;
    }
    if (ball.y > height || ball.y < 0) {
      ball.yspeed = ball.yspeed * -1;
    }
  }

  function display(p) {
    p.background(0);
    p.stroke(255);
    p.strokeWeight(4);
    p.noFill();
    p.ellipse(ball.x, ball.y, 24,24);
  }

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
