export default function Snake(p5, scl) {
  let x = 0;
  let y = 0;
  let xspeed = 1;
  let yspeed = 0;

  this.eat = (pos) => {
    let d = p5.dist(x, y, pos.x, pos.y);
    if (d < 1) {
      return true;
    } else {
      return false;
    }
  }
  this.dir = function(x, y) {
    xspeed = x;
    yspeed = y;
  }

  this.update = () => {
    x = x + xspeed*scl;
    y = y + yspeed*scl;

    x = p5.constrain(x, 0, p5.width-scl);
    y = p5.constrain(y, 0, p5.height-scl);

  }
  this.show = () => {
    p5.fill(255);
    p5.rect(x, y, 10, 10);

  }

}