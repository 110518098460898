import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
    paddingTop: '56.25%', // 16:9
  },
}));

export default function Home() {
  const classes = useStyles();
  return (
    <Card>
      <CardContent></CardContent>
      <CardMedia
        className={classes.media}
        image="/images/wordcloud.jpg"
        title="word cloud"
      />
    </Card>
    );
}
