import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Kbible from "./Kbible";

export default function ButtonDialog(props) {

  var gaspel = '';
  if ((props.value.field) === 'col4') gaspel = '마태복음';
  if ((props.value.field) === 'col5') gaspel = '마가복음';
  if ((props.value.field) === 'col6') gaspel = '누가복음';
  if ((props.value.field) === 'col7') gaspel = '요한복음';

  const [open, setOpen] = React.useState(false);
  const scroll = 'paper';

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={handleClickOpen}
      >
        {(props.value.value)}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {`${gaspel} ${props.value.value}`}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
            <Kbible gaspels={props.value.field} sections={props.value.value} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
