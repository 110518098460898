import React from "react";
import Sketch from "react-p5";
import { Bubble } from "./classes/Bubble.js";

var cnv;
var bubbles = [];

var width = 450;
var height = 400;

var b1;
var b2;

export default function P5bubbles(props) {
  const setup = (p6, canvasParentRef) => {
    cnv = p6.createCanvas(width, height).parent(canvasParentRef);
    b1 = new Bubble(p6, 250, 200, 48);
    b2 = new Bubble(p6, 350, 200, 48);
    for (var i = 0; i < 10; i++) {
      var x = p6.random(width);
      var y = p6.random(height);
      bubbles[i] = new Bubble(p6, x, y, 24);
    };
    cnv.mousePressed((event) => {
      for (var i = 0; i < bubbles.length; i++) {
        var d = p6.dist(p6.mouseX, p6.mouseY, bubbles[i].x, bubbles[i].y);
        if (d < 24) {
          bubbles.splice(i, 1);
        };
      };
    })
  }

  const draw = (p6) => {
    p6.background(0);
    b1.update();
    b2.update();
    b1.display();
    b2.display();

    if (b1.intersects(b2)) {
      b1.changeColor();
      b2.changeColor();
    }

    for (var j = 0; j < bubbles.length; j++) {
      bubbles[j].update();
      bubbles[j].display();
      for (var k = 0; k < bubbles.length; k++) {
        if (j !== k && bubbles[j].intersects(bubbles[k])) {
          bubbles[j].changeColor();
          bubbles[k].changeColor();
        }
      }
    };
  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
