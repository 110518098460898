import React, { useEffect } from "react";
import * as p5 from "p5";
 
const Lightmaterial = () => {
  const SketchLightmaterial = p5 => {
    let angle = 0;

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL)
    };
  
    p5.draw = () => {
      let dx = p5.mouseX - p5.width/2;
      let dy = p5.mouseY - p5.height/2;
      let v = p5.createVector(dx, dy, 0);
      //v.normalize();
      v.div(100);
      p5.directionalLight(255, 0, 0, v);
      //p5.pointLight(0, 0, 255, -400, 0, 0);
      //p5.pointLight(255, 0, 0, 400, 0, 0);
      p5.ambientLight(0, 0, 255);
      p5.background(175);
      p5.noStroke();
      //p5.stroke(0);
      //p5.fill(200, 0, 255);
      //p5.normalMaterial();
      p5.ambientMaterial(255);
      //p5.specularMaterial(255);
      p5.rotateX(angle);
      p5.rotateY(angle*0.03);
      p5.rotateZ(angle*0.03);
      p5.torus(100, 20);
      //p5.sphere(100);

      angle += 0.07;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchLightmaterial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default Lightmaterial;