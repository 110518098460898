import React from "react";
import Sketch from "react-p5";

export default function P5mst(props) {
  var width = 450;
  var height = 400;
  var vertices = [];
  var button;

  const setup = (p15, canvasParentRef) => {
    var cnv = p15.createCanvas(width, height).parent(canvasParentRef);
    button = p15.createButton('reset').parent(canvasParentRef);
    button.mousePressed((event) => {resetCanvas()});
    cnv.mousePressed((event)=>{
      var v = p15.createVector(p15.mouseX, p15.mouseY);
      vertices.push(v);
    })
  };

  function resetCanvas() {
    vertices = [];
  }

  const draw = (p15) => {
    p15.background(51);

    var reached = [];
    var unreached = [];

    for (var i=0; i<vertices.length; i++){
      unreached.push(vertices[i]);
    }

    reached.push(unreached[0]);
    unreached.splice(0, 1);

    while (unreached.length > 0) {
      var record = 10000;
      var rIndex;
      var unIndex;
      for (var a=0; a < reached.length; a++){
        for (var b=0; b < unreached.length; b++){
          var v1 = reached[a];
          var v2 = unreached[b];
          var d = p15.dist(v1.x, v1.y, v2.x, v2.y);
          if (d < record){
            record = d;
            rIndex = a;
            unIndex = b;
          };
        };
      };
      p15.line(reached[rIndex].x, reached[rIndex].y, unreached[unIndex].x, unreached[unIndex].y)
      reached.push(unreached[unIndex]);
      unreached.splice(unIndex, 1);
    };

    for (var j=0; j<vertices.length; j++){
      p15.fill(255);
      p15.stroke(255);
      p15.ellipse(vertices[j].x, vertices[j].y, 16, 16);
    }
  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
