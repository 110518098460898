import React from "react";
import Sketch from "react-p5";

export default function P5sliderdance(props) {
  var sliders1 = [];
  var sliders2 = [];
  var angle = 0;
  
  const setup = (p11, canvasParentRef) => {
    p11.noCanvas();
    var pSlider = document.getElementById('P5sliderdance');
    for (var i = 0; i < 100; i++) {
      sliders1[i] = p11.createSlider(10, 100, 86);
      sliders1[i].parent(pSlider);
      sliders2[i] = p11.createSlider(10, 100, 86);
      sliders2[i].parent(pSlider);
    }
    sliders2[0].input((event)=>{adjustSliders()})
  };

  const adjustSliders = () => {
    for (var i = 1; i < sliders2.length; i++) {
      sliders2[i].value(sliders2[0].value());
    }
  }

  const draw = (p11) => {
    var offset = 0;
    for (var i = 0; i < sliders1.length; i++) {
      var x = p11.map(p11.sin(angle+offset), -1, 1, 0, 255);
      sliders1[i].value(x);
      offset += 0.025;
    }
    angle += 0.1;
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
