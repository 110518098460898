import React, { useEffect } from "react";
import * as p5 from "p5";
import Ship from "./classes/Ship.js";
import Flower from "./classes/Flower.js";
import Shipdrop from "./classes/Shipdrop.js";
 
const Spaceinvaders = () => {
  const SketchSpaceinvaders = p5 => {

    let ship;
    let shipdrops = [];
    let flowers = [];
    p5.setup = () => {
      p5.createCanvas(600, 400);
      ship = new Ship(p5, p5.width, p5.height);
      for (let i = 0; i < 6; i++) {
            flowers[i] = new Flower(p5, i*80+80, 60);
      }
    };
  
    p5.draw = () => {
      p5.background(51);
      ship.show();
      ship.move();
      for (let i = 0; i < shipdrops.length; i++) {
        shipdrops[i].show();
        shipdrops[i].move();
        for (let j = 0; j < flowers.length; j++) {
          if (shipdrops[i].hits(flowers[j])) {
            flowers[j].grow();
            shipdrops[i].evaporate();
          };
        };   
      };  

      let edge = false;

      for (let i = 0; i < flowers.length; i++) {
        flowers[i].show();
        flowers[i].move();
        if (flowers[i].x > p5.width || flowers[i].x < 0) {
          edge = true;
        }
      };

      if (edge) {
        for (let i = 0; i < flowers.length; i++) {
          flowers[i].shiftDown();

        };
      }

      for (let i = shipdrops.length-1; i >= 0; i--) {
        if (shipdrops[i].toDelete) {
          shipdrops.splice(i, 1);
        };
      };
    };

    p5.keyReleased = () => {
      ship.setDir(0);
    }
    
    p5.keyPressed = () => {
      if (p5.key ===  " "){     
        let shipdrop = new Shipdrop(p5, ship.x+10, p5.height-20);
        shipdrops.push(shipdrop); 
      };
      if (p5.keyCode === p5.RIGHT_ARROW){
        ship.setDir(1);
//        shipdrops.move(1);
      };
      if (p5.keyCode === p5.LEFT_ARROW){
        ship.setDir(-1);
//        shipdrops.move(-1);
      };
    } 
  };
  
  useEffect(() => {
    const p55 = new p5(SketchSpaceinvaders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default Spaceinvaders;