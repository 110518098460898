import React from "react";
import Sketch from "react-p5";
import { Particle } from "./classes/Particle.js";

export default function P5trails(props) {
  var width = 450;
  var height = 400;
  var particles = [];
  var button;
  
  const setup = (p13, canvasParentRef) => {
    var cnv = p13.createCanvas(width, height).parent(canvasParentRef);
    cnv.mousePressed((event)=>{
      particles.push(new Particle(p13, p13.mouseX, p13.mouseY, width, height))
    })
    button = p13.createButton('reset').parent(canvasParentRef);
    button.mousePressed((event) => {resetCanvas()});
  };

  function resetCanvas() {
    particles = [];
  }

  const draw = (p13) => {
    p13.background(200);
    for (var i = 0; i < particles.length; i++) {
      particles[i].update();
      particles[i].show();
    }
  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
