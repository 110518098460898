export default function Flower(p5, xx, yy) {
  this.x = xx;
  this.y = yy;
  this.r = 30;
  this.xdir = 1;
  this.ydir = 0;

  this.show = () => {
    p5.noStroke();
    p5.fill(255, 0, 200, 150);
    p5.ellipse(this.x, this.y, this.r*2, this.r*2);
  }

  this.grow = () => {
    this.r += 2;
  }

  this.shiftDown = () => {
    this.xdir *= -1;
    this.y += this.r;
  }
  
  this.move = () => {
    this.x = this.x + this.xdir;
    this.y = this.y + this.ydir;
  }

}