import React from "react";
import Sketch from "react-p5";

export default function P5color(props) {
  const setup = (p1, canvasParentRef) => {
    p1.createCanvas(450, 400).parent(canvasParentRef);
    p1.background(250, 250, 100);
  };

  const draw = (p1) => {
    p1.noStroke();
    p1.fill(250,255,255,50);
    p1.ellipse(p1.mouseX,p1.mouseY,25,25);
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
