import React from "react";
import Sketch from "react-p5";

export default function P5pixelarray(props) {
  var width = 450;
  var height = 400;
  var button;
  var changeToggle = true;

  const setup = (p16, canvasParentRef) => {
    p16.createCanvas(width, height).parent(canvasParentRef);
    button = p16.createButton('change').parent(canvasParentRef);
    button.mousePressed((event) => {changeView(p16)});
    p16.pixelDensity(1);
  };

  function changeView(p16) {
    changeToggle = !changeToggle;
  }

  const draw = (p16) => {
    p16.background(51);
    p16.loadPixels();
    var change = 0;
    for (var y = 0; y < height; y++) {
      for (var x = 0; x < width; x++) {

        if (changeToggle) {
          change = p16.random(255);
        }
        var index = (x + y * width)*4;
        p16.pixels[index+0] = x;
        p16.pixels[index+1] = change;
        p16.pixels[index+2] = y;
        p16.pixels[index+3] = 255;
      }
    }

    p16.updatePixels();

  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
