import React, { useEffect } from "react";
import * as p5 from "p5";
import Snake from "./classes/Snake.js";

const Snakegame = () => {

  const SketchSnakegame = p5 => {
    let s;
    let scl = 20;
    let food;

    p5.setup = () => {
      p5.createCanvas(600, 600);
      s = new Snake(p5, scl);
      p5.frameRate(10);
      pickLocation();
     };

    function pickLocation() {
      let cols = p5.floor(p5.width/scl);
      let rows = p5.floor(p5.height/scl);
      food = p5.createVector(p5.floor(p5.random(cols)), p5.floor(p5.random(rows)));
      food.mult(scl);
    };
  
    p5.draw = () => {
      p5.background(51);
      s.update();
      s.show();
      if (s.eat(food)) {
        pickLocation();
      };

      p5.fill(255, 0, 100);
      p5.rect(food.x, food.y, scl, scl)
    };


    p5.keyPressed = () => {
      if (p5.keyCode === p5.UP_ARROW){
        s.dir(0, -1);
      };
      if (p5.keyCode === p5.DOWN_ARROW){
        s.dir(0, 1);
      };
      if (p5.keyCode === p5.LEFT_ARROW){
        s.dir(-1, 0);
      };
      if (p5.keyCode === p5.RIGHT_ARROW){
        s.dir(1, 0);
      };
    };

  };
  
  useEffect(() => {
    const p55 = new p5(SketchSnakegame);
  // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
  //    var elem = document.getElementById('defaultCanvas0')
  //    elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default Snakegame;