import React from "react";
import Sketch from "react-p5";

export default function P5brightnessmirror(props) {
  var width = 450;
  var height = 400;
  var button;
  var changeToggle = true;

  var xoff1 = 0;
  var xoff2 = 0;
  var xoff3 = 0;

  const setup = (p17, canvasParentRef) => {
    p17.createCanvas(width, height).parent(canvasParentRef);
    button = p17.createButton('change').parent(canvasParentRef);
    button.mousePressed((event) => {changeView(p17)});

  };

  function changeView(p17) {
    changeToggle = !changeToggle;
  }

  const draw = (p17) => {
    p17.background(51);
//    var x = p17.random(width);
    var x1 = p17.map(p17.noise(xoff1), 0, 1, 0, width);
    var x2 = p17.map(p17.noise(xoff2), 0, 1, 0, width);
    var x3 = p17.map(p17.noise(xoff3), 0, 1, 0, width);

    p17.ellipse(x1, 100, 24, 24);
    p17.rect(x2, 200, 24, 24);
    p17.square(x3, 300, 24, 5);

    xoff1 += 0.1;
    xoff2 += 0.01;
    xoff3 += 0.001;

  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
