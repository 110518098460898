import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import D3geometris from '../learning/D3geometris';
import Lightmaterial from '../learning/Lightmaterial';
import Texture from '../learning/Texture';
import Cameraperspective from '../learning/Cameraperspective';
import CreateGraphics from '../learning/CreateGraphics';
import LoadingOBJ from '../learning/LoadingOBJ';
import CustomShapes from '../learning/CustomShapes';

export default function L3dView(props) {
  let comp;
  switch (props.match.params.viewID) {
    case 'D3geometris':
      comp = (<D3geometris />);
      break;
    case 'Lightmaterial':
      comp = (<Lightmaterial />);
      break;
    case 'Texture':
      comp = (<Texture />);
      break;
    case 'Cameraperspective':
      comp = (<Cameraperspective />);
      break;
    case 'CreateGraphics':
      comp = (<CreateGraphics />);
      break;
    case 'LoadingOBJ':
      comp = (<LoadingOBJ />);
      break;
    case 'CustomShapes':
      comp = (<CustomShapes />);
      break;
    default:
  }
  
  return (
  <Card>
    <CardContent id={props.match.params.viewID}>
      {comp}
    </CardContent>
  </Card>
  );
  
}