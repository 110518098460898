import React from "react";
import Sketch from "react-p5";

export default function P5nooverlap(props) {
  var width = 450;
  var height = 400;
  var circles = [];

  const setup = (p14, canvasParentRef) => {
    p14.createCanvas(width, height).parent(canvasParentRef);

    var protection = 0;

    while (circles.length < 300) {
      var circle = {
        x: p14.random(width),
        y: p14.random(height),
        r: p14.random(6, 36)
      };

      var overlapping = false;
      for (var j = 0; j < circles.length; j++) {
        var other = circles[j];
        var d = p14.dist(circle.x, circle.y, other.x, other.y);
        if ( d < circle.r + other.r) {
          overlapping = true;
        };
      };

      if (!overlapping) {
        circles.push(circle);
      };
      
      protection++;
      if (protection > 10000) {
        break;
      };
    };

    for (let i=0;i<circles.length; i++) {
      p14.fill(255, 0, 150, 100);
      p14.noStroke();
      p14.ellipse(circles[i].x, circles[i].y, circles[i].r*2, circles[i].r*2);
    };
  };

  const draw = (p14) => {

  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
