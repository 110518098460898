import React, { useEffect } from "react";
import * as p5 from "p5";
 
const Texture = () => {
  const SketchTexture = p5 => {
    let angle = 0;
    let jung;

    p5.preload = () => {
      jung = p5.loadImage('/images/사진.jpg')
    }

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL)
    };
  
    p5.draw = () => {
      let dx = p5.mouseX - p5.width/2;
      let dy = p5.mouseY - p5.height/2;
      let v = p5.createVector(dx, dy, 0);
      v.div(100);
      p5.directionalLight(255, 255, 255, v);
      //p5.ambientLight(0, 0, 255);
      p5.noStroke();
      p5.background(175);
      p5.push();
      p5.rotateX(angle);
      p5.rotateY(angle*0.03);
      p5.rotateZ(angle*1.2);
      p5.texture(jung);
      p5.box(150);
      //p5.sphere(100);
      p5.pop();
      p5.translate(0, 150);
      p5.rotateX(p5.HALF_PI);
      p5.ambientMaterial(255);
      p5.plane(500, 500);
      angle += 0.03;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchTexture);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default Texture;