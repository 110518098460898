import React, { useEffect } from "react";
import * as p5 from "p5";
 
const StarWarsSketch = () => {
  const SketchStarWarsSketch = p5 => {
    let radius;	
    p5.setup = () => {
      p5.createCanvas(p5.windowWidth, p5.windowHeight);
      p5.background(0);
      radius = 0;
    };
  
    p5.draw = () => {
      p5.ellipse(p5.width/2,p5.height/2,radius,radius);
        radius++;  
        if (radius === p5.height) {radius = 0;}  
    };
  };
  
  useEffect(() => {
    const p55 = new p5(SketchStarWarsSketch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default StarWarsSketch;