import React, { useEffect } from "react";
import * as p5 from "p5";
import MazeCell from "./classes/MazeCell";
 
const MazeGenerator = () => {
  const SketchMazeGenerator = p5 => {

    let cols, rows;
    const w = 20;
    const grids = [];
    const stack = [];
    let current;

    p5.setup = () => {
      p5.createCanvas(400, 400);
      cols = p5.floor(p5.width/w);
      rows = p5.floor(p5.height/w);
      p5.frameRate(5);

      for (let j = 0; j < rows; j++) {
        for (let i = 0; i < cols; i++) {
          let mazecell = new MazeCell(p5, i, j, w, cols, rows, grids);
          grids.push(mazecell);
        }
      };
      current = grids[0];
    };
  
    p5.draw = () => {
      p5.background(51);
      for (let i = 0; i < grids.length; i++) {
        grids[i].show();
      }
      current.visited = true;
      current.highlight();
      // STEP 1
      let next = current.checkNeighbors();
      if (next) {
        next.visited = true;
        // STEP 2
        stack.push(current)
        // STEP 3
        removeWalls(current, next);
        current = next;
      } else if (stack.length > 0) {
        current = stack.pop();
      }
    };

    const removeWalls = (a, b) => {
      let x = a.i - b.i;
      switch (x) {
        case 1:
          a.walls[3] = false;
          b.walls[1] = false;
          break;
        case -1:
          a.walls[1] = false;
          b.walls[3] = false;
          break;
        default:
//          console.log('좌우벽을 제거하지 못했습니다.')
      }
      let y = a.j - b.j;
      switch (y) {
        case 1:
          a.walls[0] = false;
          b.walls[2] = false;
          break;
        case -1:
          a.walls[2] = false;
          b.walls[0] = false;
          break;
        default:
//          console.log('아래위벽을 제거하지 못했습니다.')
      }


    }

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchMazeGenerator);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default MazeGenerator;