import React, { useEffect } from "react";
import * as p5 from "p5";
 
const Cameraperspective = () => {
  const SketchCameraperspective = p5 => {
    let angle = 0;

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL)
    };
  
    p5.draw = () => {

      p5.background(0);

      // let camX = p5.random(-5, 5);
      // let camY = p5.random(-5, 5);
      // let camZ = p5.random(-5, 5);
      //let camX = p5.map(p5.mouseX, 0, p5.width, -200, 200)
      //p5.camera(camX, camY, camZ+(p5.height/2)/p5.tan(p5.PI/6), camX, camY, camZ, 0, 1, 0);

      // let fov = p5.PI/3;
      // let cameraZ = (p5.height/2.0) / p5.tan(fov/2.0);
      // p5.perspective(fov, p5.width/p5.height, cameraZ/10.0, cameraZ*10.0);

      //p5.ortho(-200, 200, 200, -200, -1000, 1000);
      p5.pointLight(255, 255, 255, 0, -200, 200);
      p5.noStroke();
      p5.ambientLight(255);


      for (let x = -200; x<200; x +=50) {
        p5.push();
        p5.translate(x, 0, x-200);
        p5.rotateX(angle);
        p5.rotateY(angle*0.03);
        p5.rotateZ(angle*1.2);
        p5.noStroke();
        p5.ambientLight(255);
        p5.box(50);
        p5.pop();
      }

      p5.translate(0, 100);
      p5.rotateX(p5.HALF_PI);
      p5.ambientMaterial(100);
      p5.plane(500, 500);
      angle += 0.03;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchCameraperspective);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default Cameraperspective;