import React from "react";
import Sketch from "react-p5";
import { Bubble } from "./classes/Bubble.js";

var cnv;
var bubbles = [];

var width = 450;
var height = 400;

export default function P5bubbles(props) {
  const setup = (p6, canvasParentRef) => {
    cnv = p6.createCanvas(width, height).parent(canvasParentRef);
    for (var i = 0; i < 10; i++) {
      var x = p6.random(width);
      var y = p6.random(height);
      bubbles.push(new Bubble(p6, x, y, 24));
    };
    cnv.mousePressed((event) => {
      for (var i = 0; i < bubbles.length; i++) {
        bubbles[i].clicked();
      };
    })
  }

  const draw = (p6) => {
    p6.background(0);
    for (var j = 0; j < bubbles.length; j++) {
      bubbles[j].move(p6);
      bubbles[j].display(p6);
    };
  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
