import React, { useEffect } from "react";
import * as p5 from "p5";
 
const TerrainGeneration = () => {
  const SketchTerrainGeneration = p5 => {
    let cols, rows;
    const scl = 20;
    let terrain = [];
    let flying = 0;

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL);
      p5.background(0);
      cols = 1200 / scl;
      rows = 900 / scl;
      terrain = Array.from(Array(cols), () => new Array(rows))
    };
  
    p5.draw = () => {
      flying -= 0.1;
      let yoff = flying;
      for (let y = 0; y < rows-1; y++) {
        let xoff = 0;
        for (let x = 0; x < cols; x++) {
          terrain[x][y] = p5.map(p5.noise(xoff, yoff), 0, 1, -100, 100);
          xoff += 0.2;
        }
        yoff += 0.2
      }

      p5.background(0);
      p5.rotateX(p5.PI/3);
      //p5.frameRate(5);
      p5.translate(-1200/2, - 900/2);
      p5.stroke(255);
      p5.noFill();
      for (let y = 0; y < rows-1; y++) {
        p5.beginShape(p5.TRIANGLE_STRIP);
        for (let x = 0; x < cols; x++) {
          p5.vertex(x*scl, y*scl, terrain[x][y]);
          p5.vertex(x*scl, (y+1)*scl, terrain[x][y+1]);
        }
        p5.endShape()
      }
    };
        
    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  
  };

  useEffect(() => {
    const p55 = new p5(SketchTerrainGeneration);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default TerrainGeneration;