import React from "react";
import Sketch from "react-p5";

export default function P5map(props) {
  const setup = (p3, canvasParentRef) => {
    p3.createCanvas(450, 400).parent(canvasParentRef);
    p3.background(250, 250, 100);
  };

  const draw = (p3) => {
    p3.noStroke();
    var r = p3.map(p3.mouseX,0,600,0,255);
    p3.background(r,0,0);
    p3.fill(250,255,255,50);
    p3.ellipse(p3.mouseX,p3.mouseY,25,25);
  
  };
  
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
