import React from "react";
import Sketch from "react-p5";
import Star from "./classes/Star.js";

export default function Starfield(props) {

  var stars = [];
  var button;
  var speed;

  const setup = (s1, canvasParentRef) => {
    s1.createCanvas(400, 400).parent(canvasParentRef);
    for (var i = 0; i < 400; i++) {
      stars[i] = new Star(s1, s1.width, s1.height);
    }
    button = s1.createButton('reset').parent(canvasParentRef);
    button.mousePressed((event) => {resetCanvas(s1)});
 };

  function resetCanvas(s1) {
    for (var i = 0; i < 400; i++) {
      stars[i] = new Star(s1, s1.width, s1.height);
    }
  }

  const draw = (s1) => {
    speed = s1.map(s1.mouseX, 0, s1.width, 0, 20);
    if (speed < 0) speed = 0;
    if (speed > s1.width) speed = 20;
    s1.background(0);
    s1.translate(s1.width/2, s1.height/2);
    for (var i = 0; i < stars.length; i++) {
      stars[i].update(speed);
      stars[i].show();
    };
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
