import React from "react";
import Sketch from "react-p5";

export default function P5filedragdrop(props) {
  var width = 450;
  var height = 400;
  let dropzone;
  let img;
  
  const setup = (p10, canvasParentRef) => {
    p10.createCanvas(width, height).parent(canvasParentRef);
    p10.background(0);

    dropzone = p10.select('#P5filedragdrop');
    dropzone.dragOver((event)=>{highlight()});
    dropzone.dragLeave((event)=>{unhighlight()});
    dropzone.drop((event)=>{
      unhighlight();
      gotFile(p10, event);
    });

  };

  const highlight = () => {
    dropzone.style('background-color', '#ccc');
  };

  const unhighlight = () => {
    dropzone.style('background-color', '#fff');
  };

  const gotFile = (p10, file) => {
    p10.createP(file.name + " " + file.size);
    img = p10.createImg(file.data);
    //img.hide();
    p10.image(img, 0, 0, 100, 100);
  };

  const draw = (p10) => {
    // p10.image(img, 0, 0, 200, 200);
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
