import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckID from '../works/CheckID';
import OverlapID from '../works/OverlapID';


export default function WorkView(props) {
  let comp;
  switch (props.match.params.viewID) {

    case 'CheckID':
      comp = (<CheckID />);
      break;
    case 'OverlapID':
      comp = (<OverlapID />);
      break;
    default:
  }

  return (
  <Card>
    <CardContent>
      {comp}
    </CardContent>
  </Card>
  );
  
}