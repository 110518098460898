import React from "react";
import Sketch from "react-p5";

export default function P5transparencytest(props) {
  var cnv;
  var width = 600;
  var height = 600;
  var bgcolor;
  var button;
  var slider;
  var nameInput;
  var nameP;
  var x = width/2;
  var y = height/2;
  
  const setup = (p, canvasParentRef) => {
    cnv = p.createCanvas(width, height).parent(canvasParentRef);
    cnv.parent('root');
    cnv.position(0, 0);
    cnv.mouseOver((event) => {overpara()});
    cnv.mouseOut((event) => {outpara()});
    cnv.mousePressed((event) => {changeColor(p)});
    bgcolor = p.color(0, 100, 100, 5);
    nameP = p.createP('Your name!');
    nameP.mouseOver((event) => {overpara()});
    nameP.mouseOut((event) => {outpara()});
    button = p.createButton('go');
    button.mousePressed((event) => {changeColor(p)});
    slider = p.createSlider(10, 100, 86);
    nameInput = p.createInput('type your name');
    nameInput.changed((event) => {updateText()})
    console.log(bgcolor);

  };

  function overpara() {
    nameP.html('your mouse is over me!')
  };

  function outpara() {
    nameP.html('your mouse is out!')
  };

  function changeColor(p) {
    bgcolor = p.color(p.random(255), p.random(255), p.random(255), p.random(10));
    console.log(bgcolor);
  };

  function updateText() {
    nameP.html(nameInput.value());
  };

  const draw = (p) => {
    p.background(bgcolor);
    p.fill(255, 0, 175, 5);
    p.noStroke();
    p.ellipse(x, y, slider.value(), slider.value());
    x = x + p.random(-5, 5);
    p.text(nameInput.value(), 10, 20);
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
