import React, { useEffect } from "react";
import * as p5 from "p5";
import Planet from "./classes/Planet.js";
 
const SolarSystem = () => {
  const SketchSolarSystem = p5 => {
    let sun;
    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL);
      sun = new Planet(p5, 50, 0, 0);
      sun.spawnMoons(4, 1);
    };
  
    p5.draw = () => {
      p5.background(0);
      let camX = p5.map(p5.mouseX, 0, p5.width, 200, -200);
      let camY = p5.map(p5.mouseY, 0, p5.height, 200, -200);
      p5.camera(camX, camY, (p5.height/2) / p5.tan(p5.PI/6), 0, 0, 0, 0, 1, 0);
      p5.lights();
      sun.show();
      sun.orbit();
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchSolarSystem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default SolarSystem;