import { HashRouter as Router, Route } from 'react-router-dom';
import AppShell from './components/AppShell';
import Home from './components/Home';
import Jsaid from './components/Jsaid';
import Texts from './components/Texts';
import Words from './components/Words';
import Detail from './components/Detail';
import L2dView from './components/L2dview';
import L3dView from './components/L3dview';
import M2dView from './components/M2dview';
import M3dView from './components/M3dview';
import WorkView from './components/Workview';
import Maps from './components/Maps';
import Learning2D from './components/Learning2d';
import Learning3D from './components/Learning3d';
import Make2D from './components/Make2d';
import Make3D from './components/Make3d';
import Works from './components/Works';

function App() {
  return (
    <Router>
      <AppShell>
        <div>
          <Route exact path="/" component={Home} />
          <Route exact path="/jsaid" component={Jsaid} />
          <Route exact path="/texts" component={Texts} />
          <Route exact path="/maps" component={Maps} />
          <Route exact path="/words" component={Words} />
          <Route exact path="/texts" component={Texts} />
          <Route exact path="/learning2d" component={Learning2D} />
          <Route exact path="/learning3d" component={Learning3D} />
          <Route exact path="/make2d" component={Make2D} />
          <Route exact path="/make3d" component={Make3D} />
          <Route exact path="/works" component={Works} />
          <Route exact path="/detail/:textID" component={Detail} />
          <Route exact path="/l2dview/:viewID" component={L2dView} />
          <Route exact path="/l3dview/:viewID" component={L3dView} />
          <Route exact path="/m2dview/:viewID" component={M2dView} />
          <Route exact path="/m3dview/:viewID" component={M3dView} />
          <Route exact path="/workview/:viewID" component={WorkView} />
        </div>
      </AppShell>
    </Router>
  );
}

export default App;
