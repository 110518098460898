import React, { useEffect } from "react";
import * as p5 from "p5";
import MitoCell from "./classes/MitoCell.js";
 
const MitosisSimulation = () => {
  const SketchMitosisSimulation = p5 => {
    let cells = [];	
    p5.setup = () => {
      p5.createCanvas(500, 500);
      cells.push(new MitoCell(p5, p5.width, p5.height));
      cells.push(new MitoCell(p5, p5.width, p5.height));
      cells.push(new MitoCell(p5, p5.width, p5.height));
      cells.push(new MitoCell(p5, p5.width, p5.height));
    };
  
    p5.draw = () => {
      p5.background(150);
      cells.forEach(cel => {
        cel.move();
        cel.show();
      });
    };

    p5.mousePressed = () => {
      cells.forEach(cel => {
        if (cel.clicked(p5.mouseX, p5.mouseY)) {
          cells.push(cel.mitosis());
          // cells.push(cel.mitosis());
        };
      });
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchMitosisSimulation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default MitosisSimulation;