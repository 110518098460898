export default function Drop(p5, width, height) {
  let x = p5.random(width);
  let y = p5.random(-500, -50);
  let z = p5.random(0, 20);
  let len = p5.map(z, 0, 20, 10, 20);
  let yspeed = p5.map(z, 0, 20, 1, 20);


  this.fall = () => {
    y = y + yspeed;
    let grav = p5.map(z, 0, 20, 0, 0.2);
    yspeed = yspeed +grav;

    if (y > height) {
      y = p5.random(-200, -100);
      yspeed = p5.map(z, 0, 20, 4, 10);
    }
  }

  this.show = () => {
    let thick = p5.map(z, 0, 20, 1, 3);
    p5.strokeWeight(thick);
    p5.stroke(138, 43, 226);
    p5.line(x, y, x, y+len);

  }

}