
export function Bubble(p6, x1, y1, r1) {
  this.x = x1;
  this.y = y1;
  this.r = r1;
  this.col = p6.color(255,100);
  this.display = function() {
    p6.stroke(255);
    p6.fill(this.col);
    p6.ellipse(this.x, this.y, this.r*2, this.r*2);
  };
  this.changeColor = function() {
    this.col = p6.color(p6.random(255),p6.random(255),p6.random(255));
  }
  this.intersects = function(other) {
    var d = p6.dist(this.x, this.y, other.x, other.y);
    if (d < this.r + other.r) {
      return true;
    } else {
      return false;
    }
  }
  this.clicked = function() {
    var d = p6.dist(p6.mouseX, p6.mouseY, this.x, this.y);
    if (d < 24) {
      this.col = p6.color(255,0,200);
    };
  };
  this.update = function() {
    this.x = this.x + p6.random(-1, 1);
    this.y = this.y + p6.random(-1, 1);
  };
  this.move = function() {
    this.x = this.x + p6.random(-1, 1);
    this.y = this.y + p6.random(-1, 1);
  };

};