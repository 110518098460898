import React from "react";
import Sketch from "react-p5";

export default function P5random(props) {

  var spot = {
    x: 100,
    y: 50
  }
  var col = {
    r: 255,
    g: 0,
    b: 0
  }
  var button;

  const setup = (p4, canvasParentRef) => {
    p4.createCanvas(450, 400).parent(canvasParentRef);
    p4.background(0);
    button = p4.createButton('reset').parent(canvasParentRef);
    button.mousePressed((event) => {resetCanvas(p4)});
 };

  function resetCanvas(p4) {
    p4.background(0);
  }

  const draw = (p4) => {
    p4.noStroke();
    spot.x = p4.random(450);
    spot.y = p4.random(400);
    col.r = p4.random(250);
    col.g = p4.random(250);
    col.b = p4.random(250);
    p4.fill(col.r, col.g, col.b, 50);
    p4.ellipse(spot.x, spot.y, 25, 25);
  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
