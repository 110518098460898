import React from "react";
import Sketch from "react-p5";

export default function P5domevent(props) {
  var cnv;
  var width = 450;
  var height = 400;
  var bgcolor;
  var button;
  var slider;
  var nameInput;
  var nameP;
  var x = width/2;
  var y = height/2;
  
  const setup = (p7, canvasParentRef) => {
    cnv = p7.createCanvas(width, height).parent(canvasParentRef);
//    cnv.parent('root');
//    cnv.position(0, 60);
    cnv.mouseOver((event) => {overpara()});
    cnv.mouseOut((event) => {outpara()});
    cnv.mousePressed((event) => {changeColor(p7)});
    bgcolor = p7.color(0, 100, 100, [100]);
    nameP = p7.createP('Your name!');
    nameP.parent(canvasParentRef);
    nameP.mouseOver((event) => {overpara()});
    nameP.mouseOut((event) => {outpara()});
    button = p7.createButton('go');
    button.parent(canvasParentRef);
    button.mousePressed((event) => {changeColor(p7)});
    slider = p7.createSlider(10, 100, 86);
    slider.parent(canvasParentRef);
    nameInput = p7.createInput('type your name');
    nameInput.parent(canvasParentRef);
    nameInput.changed((event) => {updateText()})
  };

  function overpara() {
    nameP.html('your mouse is over me!')
  };

  function outpara() {
    nameP.html('your mouse is out!')
  };

  function changeColor(p7) {
    bgcolor = p7.color(p7.random(255));
  };

  function updateText() {
    nameP.html(nameInput.value());
  };

  const draw = (p7) => {
    p7.background(bgcolor);
  //  p7.background(p7.color('rgba(0,255,100, 0.1)'));
    p7.fill(255, 0, 175);
    p7.ellipse(x, y, slider.value(), slider.value());
    x = x + p7.random(-5, 5);
    // nameP.html(nameInput.value());
    p7.text(nameInput.value(), 10, 20);
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
