import React from "react";
import Sketch from "react-p5";

export default function P5color(props) {
  const setup = (p2, canvasParentRef) => {
    p2.createCanvas(450, 270).parent(canvasParentRef);
  };

  const draw = (p2) => {
    p2.background(200, 200, 0);
//    p.ellipseMode(CENTER);
//    p.rectMODE(CENTER);

    // Body
    p2.fill(255, 0, 0);
    p2.rect(230,95,20,100);

    // Head
    p2.fill(0,0,255,100);
    p2.ellipse(240,115,60,60);

    // Eyes
    p2.fill(0,255,0);
    p2.ellipse(221,115,16,32);
    p2.ellipse(259,115,16,32);


    // Legs
    p2.line(230,195,220,205);
    p2.line(250,195,260,205);

  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
