import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 'auto',
  },
  title: {
    flexGrow: 1,
  },
};

class AppShell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }
  handleDrawerToggle = () => this.setState({ toggle: !this.state.toggle });
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={this.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                To Great Life ( by 정용활 )
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer open={this.state.toggle} onClose={this.handleDrawerToggle}>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/">
                홈 화면
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/jsaid">
                4복음서(예수님말씀)
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/maps">
                예수님행적 지도
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/learning2d">
                2D Study
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/learning3d">
                3D Study
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/make2d">
                2D 응용
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/make3d">
                3D 응용
              </Link>
            </MenuItem>
            {/* <MenuItem onClick={this.handleDrawerToggle}>
              <Link component={RouterLink} to="/works">업무 자동화</Link>
            </MenuItem> */}
          </Drawer>
        </div>
        <div id="content" style={{ margin: 'auto', marginTop: '20px' }}>
          {React.cloneElement(this.props.children)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AppShell);
