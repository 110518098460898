import React from "react";
import Sketch from "react-p5";

export default function P5cssparent(props) {
  var p;
  var images = [];

  const setup = (p9, canvasParentRef) => {
    var pParent = document.getElementById('P5cssparent');
    p9.noCanvas();
    var button = p9.createButton('clear images');
    button.parent(pParent);
    button.mousePressed((event)=>{clearImage()})
    for (var i=0; i<5; i++) {
      p = p9.createP('This is a link: '+i);
      p.style('background-color', '#ccc');
      p.style('padding', '24px');
      p.mousePressed((event)=>{addPhoto(p9, pParent)});
      p.parent(pParent);
    };
  };

  function clearImage() {
    for (var j=0; j<images.length; j++) {
      images[j].remove();
    };
    images = [];
  };

  function addPhoto(p9, pParent) {
    var img = p9.createImg('/images/baroapp.png')
    img.size(100, 100);
//    img.parent(this);
    img.parent(pParent);
    images.push(img);
  };

  const draw = (p9) => {
  };
  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
