export default function MazeCell(p5, i, j, w, cols, rows, grids) {
  this.i = i;
  this.j = j;
  this.walls = [true, true, true, true];
  this.visited = false;

  const index = (i, j) => {
    if (i < 0 || j < 0 || i > cols-1 || j > rows-1) return -1;
    return i + j*cols;
  }

  this.checkNeighbors = () => {
    let neighbors = [];
    let top    = grids[index(this.i, this.j-1)];
    let right  = grids[index(this.i+1, this.j)];
    let bottom = grids[index(this.i, this.j+1)];
    let left   = grids[index(this.i-1, this.j)];

    if (top && !top.visited) neighbors.push(top);
    if (right && !right.visited) neighbors.push(right);
    if (bottom && !bottom.visited) neighbors.push(bottom);
    if (left && !left.visited) neighbors.push(left);

    if (neighbors.length > 0) {
      let r = p5.floor(p5.random(0, neighbors.length));
      return neighbors[r];
    } else {
      return undefined;
    }
  }

  this.highlight = () => {
    let x = this.i * w;
    let y = this.j * w;
    p5.noStroke();
    p5.fill(255, 0, 0, 100);
    p5.rect(x, y, w, w);
  }

  this.show = () => {
    let x = this.i * w;
    let y = this.j * w;
    p5.stroke(255);
    if (this.walls[0]) p5.line(x    , y    , x + w, y);
    if (this.walls[1]) p5.line(x + w, y    , x + w, y + w);
    if (this.walls[2]) p5.line(x + w, y + w, x    , y + w);
    if (this.walls[3]) p5.line(x    , y + w, x    , y);
    if (this.visited) {
      p5.noStroke();
      p5.fill(255, 0, 255, 100);
      p5.rect(x, y, w, w);
    }
  }
}