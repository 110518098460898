import React, { useEffect } from "react";
import * as p5 from "p5";
import Drop from "./classes/Drop.js";

const Purplerain = () => {

  const SketchPurplerain = p5 => {
    let drops = [];

    p5.setup = () => {
      p5.createCanvas(640, 360);
      for (let i = 0; i < 100; i++) {
        drops[i] = new Drop(p5, p5.width, p5.height);
      }
    };

    p5.draw = () => {
      p5.background(230, 230, 250);
      for (let i = 0; i < drops.length; i++) {
        drops[i].fall();
        drops[i].show();
      }
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };

  useEffect(() => {

  const p55 = new p5(SketchPurplerain);
  // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
  //    var elem = document.getElementById('defaultCanvas0');
  //    elem.parentNode.removeChild(elem);
  //    p55.noLoop();
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default Purplerain;