export default function Ship(p5, width, height) {
  this.x = width/2;
  this.xdir = 0;

  this.show = () => {
    p5.fill(255);
    p5.rect(this.x, height-20, 20, 20);
  }

  this.setDir =(dir) => {
    this.xdir = dir;
  }
  this.move = (dir) => {
    this.x += this.xdir*5
  }

}