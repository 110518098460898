import React from "react";
import Sketch from "react-p5";

export default function P5json(props) {
  var width = 450;
  var height = 400;
  var x = 0;
  var spaceData;
  
  const setup = (p12, canvasParentRef) => {
//    p12.loadJSON('http://api.open-notify.org/astros.json', gotData(p12, ), 'jsonp')
    var data = p12.loadJSON('http://api.open-notify.org/astros.json');
    p12.createCanvas(width, height).parent(canvasParentRef);
    p12.background(0);
    gotData(p12, data);
  };

  function gotData(p12, data) {
    spaceData = data;
//    console.log(data);
  };

  const draw = (p12) => {
    p12.background(0);
    p12.stroke(255);
    p12.line(x, 0, x, height);
    x = x + 1;
    if (x > width) {
      x = 0;
    };
    if (spaceData) {
      p12.randomSeed(4);
      for (var i = 0; i < spaceData.number; i++) {
        p12.fill(255);
        p12.ellipse(p12.random(width), p12.random(height), 16, 16);
      };
    };
  };

  return (
    <div>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};
