import React from 'react';
import arrayKbible from "../json/kbible.json";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Jsaid: {
    color: 'red',
  },
}));

export default function Kbible(props) {
  const classes = useStyles();

  var gaspel='';
  if (props.gaspels === 'col4') gaspel = '마';
  if (props.gaspels === 'col5') gaspel = '막';
  if (props.gaspels === 'col6') gaspel = '눅';
  if (props.gaspels === 'col7') gaspel = '요';

  var section = props.sections.split(":");
  if (section[1].search("-") !== -1) {
    var section1 = section[1].split("-");
    var newArrayKbible = arrayKbible.filter(num => num.gospel === gaspel)
    .filter(num => num.section1 === section[0])
    .filter(num => parseInt(num.section2) >= parseInt(section1[0]))
    .filter(num => parseInt(num.section2) <= parseInt(section1[1]))
    .map((item, index) => {
      if (item.content.search(/\[/) !== -1){
        var saidJ = item.content.split("[");
        var saidJJ = saidJ[1].split("]");
        var saidJJJ = (<span>
          {saidJ[0]}
          <b className={classes.Jsaid}>{saidJJ[0]}</b>
          {saidJJ[1]}
          </span>);
        return (
          <div key={index}>
            ({item.gospel}{item.section1}:{item.section2}) {saidJJJ}<br /><br />
          </div>
        );
      }
      return (
        <div key={index}>
          ({item.gospel}{item.section1}:{item.section2}) {item.content}<br /><br />
        </div>
      );
    });
    return (
      <div>
          {newArrayKbible}
      </div>
    );
  }
  newArrayKbible = arrayKbible.filter(num => num.gospel === gaspel)
  .filter(num => num.section1 === section[0])
  .filter(num => num.section2 === section[1])
  .map((item, index) => {
    if (item.content.search(/\[/) !== -1){
      var saidJ = item.content.split("[");
      var saidJJ = saidJ[1].split("]");
      var saidJJJ = (<span>
        {saidJ[0]}
        <b className={classes.Jsaid}>{saidJJ[0]}</b>
        {saidJJ[1]}
        </span>);
      return (
        <div key={index}>
          ({item.gospel}{item.section1}:{item.section2}) {saidJJJ}<br /><br />
        </div>
      );
    }
    return (
      <div key={index}>
        ({item.gospel}{item.section1}:{item.section2}) {item.content}
      </div>
    );
  });
  return (
    <div>
        {newArrayKbible}
    </div>
  );
}
