import React from 'react';
import target_id from "../json/times2.json";
import reged_id from "../json/reg_id.json";


export default function CheckID() {

  let now = new Date();
  console.log(now);

  for(let i = 0; i < target_id.length; ++i) {
    let scount = 0;
    for(let j = 0; j < reged_id.length; ++j) {
      if (reged_id[j] === target_id[i]) scount += 1;
    };
    if (scount < 1) {
      console.log(target_id[i], scount, i);
    }
  };

  console.log('End');
  console.log(now);

  return (
    <></>
  );
}
