import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import P5draw from '../learning/P5draw';
import P5color from '../learning/P5color';
import P5map from '../learning/P5map';
import P5random from '../learning/P5random';
import P5bouncingball from '../learning/P5bouncingball';
import P5bubbles from '../learning/P5bubbles';
import P5bubblesintersection from '../learning/P5bubblesintersection';
import P5domevent from '../learning/P5domevent';
import P5cssparent from '../learning/P5css-parent';
import P5filedragdrop from '../learning/P5filedragdrop';
import P5sliderdance from '../learning/P5sliderdance';
import P5json from '../learning/P5json';
import P5trails from '../learning/P5trails';
import P5nooverlap from '../learning/P5nooverlap';
import P5mst from '../learning/P5mst';
import P5pixelarray from '../learning/P5pixelarray';
import P5perlinnoise from '../learning/P5perlinnoise';

export default function L2dView(props) {
  let comp;
  switch (props.match.params.viewID) {
    case 'P5draw':
      comp = (<P5draw />);
      break;
    case 'P5color':
      comp = (<P5color />);
      break;
    case 'P5map':
      comp = (<P5map />);
      break;
    case 'P5random':
      comp = (<P5random />);
      break;
    case 'P5bouncingball':
      comp = (<P5bouncingball />);
      break;
    case 'P5bubbles':
      comp = (<P5bubbles />);
      break;
    case 'P5bubblesintersection':
      comp = (<P5bubblesintersection />);
      break;
    case 'P5domevent':
      comp = (<P5domevent />);
      break;
    case 'P5cssparent':
      comp = (<P5cssparent />);
      break;
    case 'P5filedragdrop':
      comp = (<P5filedragdrop />);
      break;
    case 'P5sliderdance':
      comp = (<P5sliderdance />);
      break;
    case 'P5json':
      comp = (<P5json />);
      break;
      case 'P5trails':
        comp = (<P5trails />);
        break;
      case 'P5nooverlap':
        comp = (<P5nooverlap />);
        break;
      case 'P5mst':
        comp = (<P5mst />);
        break;
      case 'P5pixelarray':
        comp = (<P5pixelarray />);
        break;
      case 'P5perlinnoise':
        comp = (<P5perlinnoise />);
        break;
      default:
  }

  return (
  <Card>
    <CardContent id={props.match.params.viewID}>
      {comp}
    </CardContent>
  </Card>
  );
  
}