var gravity = 0.1;

export function Particle(p13, x1, y1, width, height) {
  this.x = x1;
  this.y = y1;
  this.a = x1;
  this.b = y1;
  this.yspeed = 0;
  this.history = [];

  this.update = function() {
    this.y += this.yspeed;
    this.yspeed += gravity;
    if (this.y > height) {
      this.y = height;
      this.yspeed *= -0.9;
    }

    this.a += p13.random(-15, 15);
    this.b += p13.random(-15, 15);

    var v = p13.createVector(this.a, this.b)
    this.history.push(v);

    if (this.history.length > 30) {
      this.history.splice(0,1);
    }
  }

  this.show = function() {
    p13.stroke(0);
    p13.fill(0, 150);
    p13.ellipse(this.x, this.y, 24, 24);

    for (var i = 0; i < this.history.length; i++) {
      var pos = this.history[i];
      p13.fill(p13.random(255));
      p13.ellipse(pos.x, pos.y, i, i);
    }
  }

};