import React, { useEffect } from "react";
import * as p5 from "p5";
 
const LoadingOBJ = () => {
  const SketchLoadingOBJ = p5 => {
    let angle = 0;
    let ironman;

    p5.preload = () => {
      ironman = p5.loadModel('/images/Furniture_Table.obj')
    }

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL).parent('root').position(0,0);
    };
  
    p5.draw = () => {
      p5.background(255, 0);
      p5.scale(15);
      p5.ambientLight(100);
      p5.directionalLight(255, 255, 255, 0, 0, 1);
      p5.rotateX(angle);
      p5.rotateY(angle*0.3);
      p5.rotateZ(angle*0.7);
      p5.translate(0, 0, 0);
      p5.normalMaterial(255);
      p5.model(ironman);
      angle += 0.03;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchLoadingOBJ);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default LoadingOBJ;