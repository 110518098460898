import React, { useEffect } from "react";
import * as p5 from "p5";
 
const CreateGraphics = () => {
  const SketchCreateGraphics = p5 => {
    let angle = 0;
    let graphics;
    //let love;

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL)
      p5.noStroke();
      graphics = p5.createGraphics(200, 200);
      graphics.background(255);
      // love = p5.createGraphics(200, 200);
      // love.background(255, 100);
      // love.fill(255);
      // love.textAlign(p5.CENTER);
      // love.textSize(64);
      // love.text('love', 100, 50);
    };
  
    p5.draw = () => {
      graphics.fill(255, 0, 255);
      graphics.ellipse(p5.mouseX, p5.mouseY, 20);
      p5.background(0);
      p5.ambientLight(100);
      p5.directionalLight(255, 255, 255, 0, 0, -1);
      p5.rotateX(angle);
      p5.rotateY(angle*1.3);
      p5.rotateZ(angle*0.7);
      p5.texture(graphics);
      //p5.texture(love);
      p5.box(100);
      angle += 0.03;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchCreateGraphics);
    return () => {
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default CreateGraphics;