export default function Planet(p5, r, d, o) {

  let radius = r;
  let angle = p5.random(p5.HALF_PI);
  let distance = d;
  let planets = [];
  let orbitSpeed = o;
  let v = p5.createVector(p5.random(),p5.random(),p5.random()).mult(distance);

  this.orbit = () => {
    angle = angle + orbitSpeed;
    planets.forEach(planet => planet.orbit());
  }
  
  this.spawnMoons = (total, level) => {
    for (let i = 0; i < total; i++) {
      let _r = radius/(level*2);
      let _d = p5.random((radius + _r)*2, (radius + _r)*3);
      let _o = p5.random(-0.1, 0.1);
      planets[i] = new Planet(p5, _r, _d, _o);
      if (level < 2) {
        let nums = p5.int(p5.random(0, 3));
        planets[i].spawnMoons(nums, level+1);
      }
    }
  }

  this.show = () => {
    p5.push();
//    p5.noStroke();
//    let v2 = p5.createVector(1, 0, 1);
//    let p = p5.createVector();
//    p = v.cross(v2);
//    v.rotate(angle, p.x, p.y, p.z);
    p5.rotate(angle);
    p5.stroke(255);
    // p5.line(0, 0, 0, v.x, v.y, v.z)
    // p5.line(0, 0, 0, p.x, p.y, p.z)
    p5.translate(v.x, v.y, v.z);
//    p5.noStroke();
    p5.fill(255);
    p5.sphere(radius);
    planets.forEach(planet => planet.show());
    p5.pop();
  }

}