
export default function Star(s, width, height) {
  this.x = s.random(-width, width);
  this.y = s.random(-height, height);
  this.z = s.random(width);
  this.col = s.color(s.random(255), s.random(255), s.random(255));

  this.pz = this.z;

  this.show = function() {
    s.noStroke();
    s.fill(this.col);
    var sx = s.map(this.x/this.z, 0, 1, 0, width);
    var sy = s.map(this.y/this.z, 0, 1, 0, height);
    var r = s.map(this.z, 0, width, 16, 0);
    s.ellipse(sx, sy, r, r);
    var px = s.map(this.x/this.pz, 0, 1, 0, width);
    var py = s.map(this.y/this.pz, 0, 1, 0, height);

    s.stroke(this.col);
    s.line(px, py, sx, sy);

    this.px = this.x;
    this.py = this.y;
  };

  this.update = (speed) => {
    this.z = this.z - speed;
    if (this.z < 1) {
      this.z = width;
      this.x = s.random(-width, width);
      this.y = s.random(-height, height);
      this.pz = this.z;
    }
  };
};