export default function MitoCell(p5, width, height, pos, r, c) {

  let _pos;
  if (pos) {
    _pos = pos.copy();
  } else {
    _pos = p5.createVector(p5.random(width), p5.random(height));
  }
  const _r = r || 60;
  const _c = c || p5.color(p5.random(100, 255), 0, p5.random(100, 255), 100);


  this.clicked = (x, y) => {
    let d = p5.dist(_pos.x, _pos.y, x, y);
    return (d < _r/2) ? true : false;
  }

  this.mitosis = () => {
    _pos.x += p5.random(-10, 10);
    _pos.y += p5.random(-10, 10);
    let cell = new MitoCell(p5, width, height, _pos, _r, _c);
    return cell;
  }

  this.move = () => {
    let vel = p5.createVector(p5.random(-1, 1), p5.random(-1, 1));
    _pos.add(vel);
  }

  this.show = () => {
    p5.noStroke();
    p5.fill(_c);
    p5.ellipse(_pos.x, _pos.y, _r, _r);

  }

}