import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SolarSystem from '../make/SolarSystem';
import TerrainGeneration from '../make/TerrainGeneration';
import LorenzAttractor from '../make/LorenzAttractor';



export default function M3dView(props) {
  let comp;
  switch (props.match.params.viewID) {
    case 'SolarSystem':
      comp = (<SolarSystem />);
      break;
    case 'TerrainGeneration':
      comp = (<TerrainGeneration />);
      break;
    case 'LorenzAttractor':
      comp = (<LorenzAttractor />);
      break;
    default:
  }

  return (
  <Card>
    <CardContent id={props.match.params.viewID}>
      {comp}
    </CardContent>
  </Card>
  );
  
}