import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import ButtonDialog from './ButtonDialog';
import array4gaspel from "../json/4gaspel.json";

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();
  const classes = useStyles();

  return (
    <Pagination
      className={classes.root}
      color="primary"
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: 'col1', headerName: '시기', headerAlign: 'center', sortable: false, width: 120 },
  { field: 'col2', headerName: '장소', headerAlign: 'center', sortable: false, width: 120 },
  { field: 'col3', headerName: '주제', headerAlign: 'center', sortable: false, width: 200 },
  { field: 'col4', align: 'center', headerName: '마태복음', headerAlign: 'center', sortable: false, width: 105, 
    renderCell: (params) => handleButton(params)
  },
  { field: 'col5', align: 'center', headerName: '마가복음', headerAlign: 'center', sortable: false, width: 105,
    renderCell: (params) => handleButton(params)
  },
  { field: 'col6', align: 'center', headerName: '누가복음', headerAlign: 'center', sortable: false, width: 105,
    renderCell: (params) => handleButton(params)
  },
  { field: 'col7', align: 'center', headerName: '요한복음', headerAlign: 'center', sortable: false, width: 105,
    renderCell: (params) => handleButton(params)
  },
];

function handleButton(params) {
  if (params.value) {
    return (
      <strong>
        <ButtonDialog value={(params)}>
          {(params.value)}
        </ButtonDialog>
      </strong>
    )
  } else {
    return ''
  }
}

export default function Jsaid() {

  var newArray4gaspel = array4gaspel.map((item, index) => {
    return {"id": index, "mtjs": item.mtjs, "mgjs": item.mgjs, "ngjs": item.ngjs, "jhjs": item.jhjs, "col1": item.time, "col2": item.place, "col3": item.subject , "col4": item.matthew , "col5": item.mark , "col6": item.luke , "col7": item.john}
  });
  
  const [value, setValue] = React.useState('time');
  const [arrayRow, setArrayRow] = React.useState(newArray4gaspel);

  const handleChange = (event) => {
    if (event.target.value ==='time') {
      newArray4gaspel = array4gaspel.filter(num => parseInt(num.index) >= 1).sort((a,b) => parseInt(a.index) - parseInt(b.index))
    } else if (event.target.value ==='matthew') {
      newArray4gaspel = array4gaspel.filter(num => parseInt(num.mtnum) >= 1).sort((a,b) => parseInt(a.mtnum) - parseInt(b.mtnum))
    } else if (event.target.value ==='mark') {
      newArray4gaspel = array4gaspel.filter(num => parseInt(num.mgnum) >= 1).sort((a,b) => parseInt(a.mgnum) - parseInt(b.mgnum))
    } else if (event.target.value ==='luke') {
      newArray4gaspel = array4gaspel.filter(num => parseInt(num.ngnum) >= 1).sort((a,b) => parseInt(a.ngnum) - parseInt(b.ngnum))
    } else {
      newArray4gaspel = array4gaspel.filter(num => parseInt(num.jhnum) >= 1).sort((a,b) => parseInt(a.jhnum) - parseInt(b.jhnum))
    }

    var new1Array4gaspel = newArray4gaspel.map((item, index) => {
      return {"id": index, "mtjs": item.mtjs, "mgjs": item.mgjs, "ngjs": item.ngjs, "jhjs": item.jhjs, "col1": item.time, "col2": item.place, "col3": item.subject , "col4": item.matthew , "col5": item.mark , "col6": item.luke , "col7": item.john}
    });

    setArrayRow(new1Array4gaspel);
    setValue(event.target.value);

  };

  var rows = arrayRow;

  return (
    <div>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">정렬 기준 :</FormLabel>
          <RadioGroup row aria-label="정렬" name="gender1" value={value} onChange={handleChange}>
            <FormControlLabel value="time" control={<Radio />} label="시기순" />
            <FormControlLabel value="matthew" control={<Radio />} label="마태복음" />
            <FormControlLabel value="mark" control={<Radio />} label="마가복음" />
            <FormControlLabel value="luke" control={<Radio />} label="누가복음" />
            <FormControlLabel value="john" control={<Radio />} label="요한복음" />
          </RadioGroup>
        </FormControl>
    </div><br />
      <div style={{ height: 500, width: '100% '}}>
        <DataGrid
          disableSelectionOnClick={true}
          disableColumnMenu={true}
          rows={rows}
          columns={columns}
          pageination
          rowLength={arrayRow.length}  // CustomPagination()
          pageSize={15}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          localeText={{
            toolbarDensity: '줄높이',
            toolbarDensityCompact: '좁게',
            toolbarDensityStandard: '보통',
            toolbarDensityComfortable: '넓게',
            toolbarColumns: '항목숨기기',
            toolbarFilters: '찾기',
          }}
        />
      </div>
    </div>
  );
}
