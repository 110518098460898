import React, { useEffect } from "react";
import * as p5 from "p5";
 
const D3geometris = () => {
  const SketchD3geometris = p5 => {
    let angle = 0;

    p5.setup = () => {
      p5.createCanvas(600, 600, p5.WEBGL)
    };
  
    p5.draw = () => {
      p5.background(175);
      p5.rectMode(p5.CENTER);
      p5.noStroke();
      p5.fill(0, 0, 255);
      p5.translate(0, 0, p5.mouseX);
      p5.rotateX(angle);
      p5.rotateY(angle*0.03);
      p5.rotateZ(angle*0.03);
      p5.rect(0, 0, 150, 150);

      angle += 0.07;
    };

    p5.mousePressed = () => {
      p5.noLoop();
    }
    
    p5.mouseReleased = () => {
      p5.loop();
    }
  };
  
  useEffect(() => {
    const p55 = new p5(SketchD3geometris);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
    //  var elem = document.getElementById('defaultCanvas0')
    //  elem.parentNode.removeChild(elem);
      p55.remove();
    };
  }, []);
  
  return (
      <></>
  );
};
 
export default D3geometris;