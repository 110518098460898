import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Transparencytest from '../make/Transparencytest';
import Starfield from '../make/Starfield';
import StarWarsSketch from '../make/StarWarsSketch';
import Snakegame from '../make/Snakegame';
import Purplerain from '../make/Purplerain';
import Spaceinvaders from '../make/Spaceinvaders';
import MitosisSimulation from '../make/MitosisSimulation';
import MazeGenerator from '../make/MazeGenerator';


export default function M2dView(props) {
  let comp;
  switch (props.match.params.viewID) {
    case 'Transparencytest':
      comp = (<Transparencytest />);
      break;
    case 'Starfield':
      comp = (<Starfield />);
      break;
    case 'Snakegame':
      comp = (<Snakegame />);
      break;
    case 'StarWarsSketch':
      comp = (<StarWarsSketch />);
      break;
    case 'Purplerain':
      comp = (<Purplerain />);
      break;
    case 'Spaceinvaders':
      comp = (<Spaceinvaders />);
      break;
    case 'MitosisSimulation':
      comp = (<MitosisSimulation />);
      break;
    case 'MazeGenerator':
      comp = (<MazeGenerator />);
      break;
    default:
  }

  return (
  <Card>
    <CardContent id={props.match.params.viewID}>
      {comp}
    </CardContent>
  </Card>
  );
  
}